.page-product-list-number{
    width: 50px;
    max-width: 51px;
}
.page-product-list-view{
    width: 100px;
    max-width: 106px;
}
.product-item-all-regulatory:focus, .product-item-all-regulatory:focus td{
  background-color: #f5f5f5;
}
.product-item td{
    color: white;
    cursor: pointer;
}