.form-group-bar {
    display: flex;
    flex-wrap: nowrap;
    padding: 1.25rem;
    align-items: center;
    place-items: center;
}

.form-group-bar .form-control{
    margin-left: 2rem;
}

.form-group-bar-search-button{
    background: url('/src/lib/img/search.png') no-repeat;
    background-position: center;
    align-items: center;
    height: calc(1.5em + 0.75rem + 2px);
    opacity: 1;
    display: block;
    border-width: thin;
    border-left: none;
    border-radius: 0.25rem;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    width: 5em;
    padding: 1rem;  
}

.form-group-bar-search{
    border-right: none!important;
    border-top-right-radius: 0px!important;
    border-bottom-right-radius: 0px!important;
}

.form-main-navbar{
    position: fixed!important;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}