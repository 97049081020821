.request-card {
    margin-top: 40px;
}

.request-card input,
.request-card select,
.request-card textarea {
    font-size: 14px;
    font-weight: 400;
    color: #767d83;
}

.request-card .form-control[readonly] {
    background-color: #fff;
}

.request-card input[type="date"]::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    display: block;
    background: url('/src/lib/img/calendar.png') no-repeat;
    width: 20px;
    height: 20px;
    border-width: thin;
}

.request-card label {
    font-size: 16px;
    font-weight: 500;
    color: #677787;
}

.request-card-title {
    text-align: center;
}

.request-btn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    padding: 5px 50px;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 400;
    border: 0;
    border-radius: 15px;
    color: #fff;
    background-color: #767d83;
    transition: all 0.2s ease-in;
}

.request-btn:hover {
    background-color: #5bbabd;
}

.request-form-file[type="file"] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.request-file-label {
    display: inline-block;
    cursor: pointer;
    font-size: 14px;
    color: #767d83;
    margin-top: 5px;
    padding: 2px 10px;
    background-color: #f5f5f5;
    margin-right: 15px;
}

.request-file-label span {
    font-size: 14px;
    font-weight: 400;
    color: #767d83;
}

.form-label span {
    font-size: 14px;
    color: #767d83;
}

.request-file-label img {
    margin-left: 15px
}

.errorMessageRed{
  text-align: center;
  background-color: #ff00000d;
  padding: 1em;
  border: solid #ff000038 1px;
}

.drop-area{
    border: dashed #5bbabd 0.1em;
    border-radius: 0.2em;
    padding: 0.2em;
}


#fileslbl {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25vh;
    background-color: red;
    color: white;
    padding: 16px;
    text-align: center;
    transition: .5s;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
}
#filesfld {
    display: none;
}
#progress {
    width: 0;
    background-color: blue;
    color: white;
    font-weight: bold;
    font-size: 14px;
    line-height: 25px;
    padding: 0 5px;
}
@keyframes dropbox {
    0% {
        background-image: repeating-linear-gradient(30deg, green 1%, green 3%, darkgreen 5%, darkgreen 5%);
    }
    50% {
        background-image: repeating-linear-gradient(30deg, darkgreen 1%, darkgreen 3%, green 5%, green 5%);
    }
    100% {
        background-image: repeating-linear-gradient(30deg, green 1%, green 3%, darkgreen 5%, darkgreen 5%);
    }
}


main {
    display: flex;
    flex-direction: column;
  }
  
  header {
    margin-top: 1rem;
  }
  
  .droparea {
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 384px;
    max-width: 100%;
    height: 160px;
    border: 4px dashed grey;
    border-radius: 15px;
  }
  
  .droparea i {
    font-size: 3rem;
    flex-grow: 1;
    padding-top: 1rem;
  }
  
  .green-border {
    border-color: green;
  }