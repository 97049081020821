.border-right-left-bottom-0 th{
border-right: 0!important; 
border-left: 0!important; 
border-bottom: 0!important; 
}

.request-item-header th {
    text-align: center;
    font-family: Montserrat Medium;
    font-size: 14px!important;
    color: #677787!important;
    font-weight:normal;
}
.request-item-header td {
    font-family: Montserrat Regular;
    font-size: 14px!important;
    color: #677787!important;
}

.page-users-list-number{
    width: 40px;
    max-width: 51px;
}
.page-users-list-license{
    width: 90px;
    max-width: 100px;
}
.page-users-list-phone{
    width: 110px;
    max-width: 110px;
    min-width: 100px;
}
.page-users-list-email{
    width: 209px;
    min-width: 200px;
}
.page-users-list-company{
    min-width: 185px;
}