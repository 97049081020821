.navbar-title{
    font-size: 20px;
    font-weight: 600;

    text-decoration: none;
    color: #767d83;
    margin-right: 6rem;
}

.navbar-title:hover{
    text-decoration: none;
    color: #767d83;
}

.nav-item{
    color: #767b83;
    display: block;
    padding: .5rem 1rem;
    transition: all 0.2s ease-in;
}

.nav-item:hover{
    color: #5bbabd;
    text-decoration: none;
}

.nav-item.active{
    color: #5bbabd;
}

.form-main-navbar{
    background-color: white;
}
