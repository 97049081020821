
.btn-primary-look-Login{
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    background: url('/src/lib/img/look.png') no-repeat;
    width: 25px;
    height: 25px;
    border-width: thin;
    margin-left: -1.6em !important;
    position: absolute;
    margin-top: 2.3em;
    right: 2em;
  }