.row:first-child {
    margin-left: 0;
    margin-right: 0;
}
.color-td:first-child {
    margin-left: -1.26rem;
}

.card-request-item {
    border: 1px solid rgba(0,0,0,.125);
}

.request-item .col {
    font-family: Montserrat Regular;
    font-size: 14px!important;
    color: #677787!important;
}

.request-item-header .col {
    font-family: Montserrat Medium;
    font-size: 14px!important;
    color: #677787!important;
}
.card-request{
    min-width: 650px;
    text-align: center;
}
.card-request .row{
    flex-wrap: nowrap;
}
.color-td-heder{
    width: 31px;
    margin-left: -1.26rem;
}
.request-admin-list-company{
    min-width: 260px;
    /* background-color: red;
    min-width: 700px; */
}
.request-admin-list-show{
    min-width: 1em;
    max-width: 1em!important;
}
.request-admin-list-product{
    min-width: 180px;
}
/* @media (min-width: 992px){ */
@media (max-width: 992px){
    .card-body .card-request-admin{
        min-width: 900px;
    }
    .card-body .card-request-user{
        min-width: 650px;
    }
    .card-request-user .card-request-item .card-body{
        padding-right: 0px;
    }
    .card-request-user .request-item-header{
        padding-right: 2.25rem;
    }
}