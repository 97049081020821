.account-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
}

.auth-card {
  width: 500px;
  margin: 15px;
  background-color: #fff0;
  border: 0;
}

#auth-card-login {
  background-color: #fff0;
  border: 0;
}

.auth-card-title:first-child {
  font-size: 22px!important;
  text-align: center;
}

.h5 {
  font-family: Montserrat SemiBold;
  font-size: 18px!important;
  text-transform: none;
  color: #767d83;
}

.form-label {
  font-family: Montserrat Medium;
  font-size: 16px!important;
  color: #677787;
}

.form-control,
.my-2 {
  font-family: Montserrat Regular;
  font-size: 14px!important;
  color: #767d83;
}

#btn-login,
#btn-register,
.btn-light-theme  {
  font-family: Montserrat Regular;
  background-color: #677787;
  border: none;
  color: white;
  border-radius: 18px;
  -moz-border-radius: 18px;
  -webkit-border-radius: 18px;
  font-size: 14px;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  padding: 6px 4em 6px 4em;
  margin: 10px 0 0 0;
  white-space: nowrap;
}
#btn-warning-theme  {
  font-family: Montserrat Regular;
  background-color: #677787;
  border: 1px solid transparent;
  color: white;
  border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -webkit-border-radius: 0.25rem;
  font-size: 1rem;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
}
#btn-warning-theme:hover {
  background: #5bb9bb;
  color: #fff;
  text-decoration: none;
}
.btn-secondary:hover {
  background-color: #5bb9bb!important;
  color: #fff;
  text-decoration: none;
  border: 1px solid transparent!important;
}
#btn-register {
  padding: 6px 2em 6px 2em;
}

#btn-login:hover,
#btn-register:hover,
.btn-light-theme:hover {
  background: #5bb9bb;
  color: #fff;
  text-decoration: none;
}

#btn-login:focus,
#btn-login:active,
#btn-register:focus,
#btn-register:active {
  outline: none;
}

.btn-login-conteiner {
  text-align: center;
}

#btn-password-reset {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  font-family: Montserrat Regular;
  border: none;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
}

#btn-password-reset:hover {
  border: none;
  text-decoration: underline;
}

#btn-password-reset:focus,
#btn-password-reset:active {
  border: none;
  outline: none;
}

