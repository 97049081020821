.request-item {
  color: white;
  cursor: pointer;
}

.request-item-regulatory {
  background-color: #56b8bb;
  border-right: 1px solid rgba(0,0,0,.125);
  border-left: 1px solid rgba(0,0,0,.125);
}
/* 
.request-item-regulatory :hover {
  background-color: rgb(164, 192, 164);
} */

.request-item-planning {
  background-color: #ffce43;
  border-right: 1px solid rgba(0,0,0,.125);
  border-left: 1px solid rgba(0,0,0,.125);
}

.request-item-critical {
  background-color: #e95740;
  border-right: 1px solid rgba(0,0,0,.125);
  border-left: 1px solid rgba(0,0,0,.125);
}

.request-item-normal {
  background-color: #f5f5f5;
  border-right: 1px solid rgba(0,0,0,.125);
  border-left: 1px solid rgba(0,0,0,.125);
  /* background-color: rgb(145, 141, 141); */
}

.request-item-all-regulatory:focus, .request-item-all-regulatory:focus .col{
  background-color: #56b8bb;
  color: #ffffff!important;
}

.request-item-all-planning:focus {
  background-color: #ffce43;
}

.request-item-all-critical:focus, .request-item-all-critical:focus .col{
  background-color: #e95740;
  color: #ffffff!important;
}

.request-item-all-normal:focus {
  background-color: #f5f5f5;
}

#colCol {
  color: black;
  cursor: pointer;
}

.color-td {
  min-width: 30px;
  margin: -1.25rem 0 -1.25rem -0.26rem;
  padding: 0;
}