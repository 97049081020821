.App {
  text-align: center;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 400;
  padding-top: 5rem;
}

.right-0 {
  right: 0 !important;
}
.top-0 {
  top: 0 !important;
}
.float-right {
  float: right;
}