#idCard {
    background-color: #fff0!important;
    border: 0;
}

#idTitle {
    font-family: Montserrat SemiBold;
    background-color: #fff0!important;
    border: 0;
    color: #767d83!important;
    font-size: 18px;
}

.form-control-plaintext {
    font-family: Montserrat Regular;
    font-size: 16px!important;
    color: #677787!important;
}

#form-control-user, #form-control-user-pass {
    font-family: Montserrat Regular;
    font-size: 16px!important;
    color: #677787!important;
    border: none;
    padding: 0;
}

#btn-primary-user {
    background-color: #fff0;
    color: #0069d9;
    border: none;
    font-size: 16px;
}

.form-control-phone {
    min-width: 145px;
}
.row-first-child:first-child{
    margin-left: -15px;
}

.errorMessageRed{
    text-align: center;
    background-color: #ff00000d;
    padding: 1em;
    border: solid #ff000038 1px;
}
.successMessage{
    text-align: center;
    background-color: rgba(187, 246, 215, 0.16);
    padding: 1em;
    border: dashed #5bbabd 0.1em;
    border-radius: 0.2em;
  }

.btn-primary-look-UserProfile{
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    background: url('/src/lib/img/look.png') no-repeat;
    width: 25px;
    height: 25px;
    border-width: thin;
    margin-left: -1.6em !important;
  }